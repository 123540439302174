import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Teleport as _Teleport, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container-wrapper" }
const _hoisted_3 = { class: "playerSelection" }
const _hoisted_4 = { class: "playerSelection_wrapper" }
const _hoisted_5 = { class: "button__wrapper" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "button__inner" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "button__number"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "playerSelection__note" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "playerSelection__button" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<section class=\"gametitle\"><h1>Die Party Überraschung</h1><h2>Das ganz große Chaos</h2></section><section class=\"hero\"><img src=\"https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/images%2Fbirthday-party%2Fintro%2Flisa_und_paul.jpg?alt=media&amp;token=7b11a066-4cc1-4fa2-b22c-3f45e59dc466\"></section><section class=\"audio\"><audio src=\"https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/audio%2Fbirthday-party%2FEingangstext_Audio_8Tage.mp3?alt=media&amp;token=22346a2b-310a-4be0-a3a6-4115791262ec\" controls preload=\"auto\"></audio></section><section class=\"introtext\"><p>Lisa und Paul sind bereits seit Schultagen die besten Freunde von Chrissy. Sie haben schon viel zusammen erlebt und schwelgen gerne in den alten Geschichten. Ihr Motto:</p><p class=\"strong\"><strong>Auf Freundschaft. Auf Erinnerungen. Auf Partynächte. Auf Lachanfälle. Auf Abenteuer. Auf UNS!</strong></p><p>Zu Chrissys anstehenden 25. Geburtstag haben sie sich daher ein besonderes Geschenk einfallen lassen. Eine Überraschungsparty soll es werden. Eine neue Gelegenheit um gemeinsame Erinnerungen zu schaffen, ganz getreu ihrem Motto.</p><p>Heimlich haben sie sich dafür getroffen und bei Pizza und Bier die Planung durchdacht. Doch irgendwie scheint der Abend außer Kontrolle geraten zu sein …</p><p>Am nächsten Morgen können sie sich nicht mehr daran erinnern was genau passiert ist. Und von ihrer ganzen Arbeit sind nur noch Bruchstücke übrig. Doch die Zeit drängt! Der Geburtstag ist bereits in 8 Tagen und noch ist nichts organisiert.<br>Können sie es schaffen doch noch DIE legendäre Party auf die Beine zu stellen?</p><p>Eure Hilfe ist hier gefragt:</p><p>Teilt euch auf und entscheidet, wer von euch Lisa und wer Paul bei dieser wichtigen Aufgabe unterstützt - und schon geht es los!</p></section>", 4)),
      _createElementVNode("section", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Wem willst du helfen?", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playersRef, (player, index) => {
              return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["btn-circle", {online: (player.players > 0 && player.id === index)}]),
                onClick: ($event: any) => (_ctx.selectPlayer(index)),
                key: player.id,
                ref_for: true,
                ref: _ctx.setItemRef
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: player.img
                  }, null, 8, _hoisted_8),
                  (player.players > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("span", null, _toDisplayString(player.players), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  class: "button__reset",
                  onClick: _withModifiers(($event: any) => (_ctx.resetPlayer(index)), ["stop"])
                }, "×", 8, _hoisted_10)
              ], 10, _hoisted_6))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_Transition, {
            name: "fade",
            appear: ""
          }, {
            default: _withCtx(() => [
              (_ctx.otherPlayersIndexRef > -1 && _ctx.showNoteRef)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.playerNames[_ctx.otherPlayersIndexRef]), 1),
                    _cache[1] || (_cache[1] = _createTextVNode(" braucht auch Hilfe!"))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            class: "btn text-white bg-redpurple-900",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enterRoom())),
            disabled: !(_ctx.playerIdRef !== null) || (_ctx.otherPlayersIndexRef > -1 && _ctx.showNoteRef)
          }, "Spiel starten", 8, _hoisted_14)
        ])
      ]),
      _createVNode(_component_Footer)
    ]),
    (_ctx.modalContent)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "body"
        }, [
          _createVNode(_component_Modal, { componentName: _ctx.modalContent }, null, 8, ["componentName"])
        ]))
      : _createCommentVNode("", true)
  ]))
}