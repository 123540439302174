import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "backdrop",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleClose()), ["self"]))
  }, [
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "modal__close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("svg", {
            width: "40px",
            height: "40px",
            viewbox: "0 0 40 40"
          }, [
            _createElementVNode("path", {
              class: "close-x",
              d: "M 10,10 L 30,30 M 30,10 L 10,30"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "modal",
      appear: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["modal__container", _ctx.modalCoomponent.toLowerCase()])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
            path: _ctx.path,
            solutionChat: _ctx.solutionChat,
            solutionQuestion: _ctx.solutionQuestion,
            timestamp: _ctx.timestamp,
            onCloseModal: _ctx.handleClose,
            onChangeComponent: _ctx.changeComponent
          }, null, 40, ["path", "solutionChat", "solutionQuestion", "timestamp", "onCloseModal", "onChangeComponent"]))
        ], 2)
      ]),
      _: 1
    })
  ]))
}