import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-center" }
const _hoisted_2 = { class: "authentification" }
const _hoisted_3 = { class: "authentification__wrapper" }
const _hoisted_4 = { class: "authentification__form" }
const _hoisted_5 = { class: "error-message" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "authentification__intro" }, [
          _createElementVNode("h2", null, "Login")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", {
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.error = false)),
              placeholder: "E-Mail",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.email]
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.error = false)),
              placeholder: "Password",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.password]
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Die Zugangsdaten sind ungültig"))
                : _createCommentVNode("", true)
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("button", { type: "submit" }, "Enter", -1))
          ], 32)
        ])
      ])
    ])
  ]))
}