import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelRadio as _vModelRadio, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "admin-panel" }
const _hoisted_2 = { class: "admin-wrapper" }
const _hoisted_3 = { class: "admin-header" }
const _hoisted_4 = { class: "admin-logout" }
const _hoisted_5 = { class: "admin-toggle" }
const _hoisted_6 = { class: "admin-body" }
const _hoisted_7 = { class: "form-wrapper" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-group-col" }
const _hoisted_10 = ["value", "textContent"]
const _hoisted_11 = { class: "form-group-col" }
const _hoisted_12 = ["checked"]
const _hoisted_13 = ["checked"]
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-group-col" }
const _hoisted_16 = ["value", "textContent"]
const _hoisted_17 = { class: "form-group separater" }
const _hoisted_18 = { class: "form-group-col" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "form-group-col" }
const _hoisted_21 = { class: "form-group-col" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "form-group-col" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = { class: "form-group-col" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = { class: "form-group-col" }
const _hoisted_28 = { class: "form-group-col" }
const _hoisted_29 = { class: "admin-footer" }
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = { class: "form-group-col" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = ["disabled"]
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[21] || (_cache[21] = _createElementVNode("h3", null, "Edit Hotspots", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
          }, "logout")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("input", {
            class: "toggleButton",
            id: "toggleButton",
            type: "checkbox",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.adminState = !_ctx.adminState))
          }, null, 32),
          _cache[20] || (_cache[20] = _createElementVNode("label", {
            class: "toggleLabel",
            for: "toggleButton"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("select", {
                id: "rooms",
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePano($event))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.roomId) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: room.room,
                    value: room.room,
                    textContent: _toDisplayString(room.name)
                  }, null, 8, _hoisted_10))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.roomId]
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("label", { for: "rooms" }, "Room", -1))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                id: "player1",
                type: "radio",
                name: "player",
                value: "0",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.playerId) = $event)),
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.chgPlayer && _ctx.chgPlayer(...args))),
                checked: _ctx.playerId === 0
              }, null, 8, _hoisted_12), [
                [_vModelRadio, _ctx.playerId]
              ]),
              _cache[23] || (_cache[23] = _createElementVNode("label", { for: "player1" }, "Player 1", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "player2",
                type: "radio",
                name: "player",
                value: "1",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.playerId) = $event)),
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.chgPlayer && _ctx.chgPlayer(...args))),
                checked: _ctx.playerId === 1
              }, null, 8, _hoisted_13), [
                [_vModelRadio, _ctx.playerId]
              ]),
              _cache[24] || (_cache[24] = _createElementVNode("label", { for: "player2" }, "Player 2", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("select", {
                id: "hotspots",
                onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectHotspot($event))),
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.hotspotId) = $event))
              }, [
                _cache[25] || (_cache[25] = _createElementVNode("option", {
                  value: "",
                  selected: ""
                }, "New Hotspot", -1)),
                (_ctx.hotspotList.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.hotspotList, (hotspot, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: hotspot.id,
                        textContent: _toDisplayString(hotspot.name)
                      }, null, 8, _hoisted_16))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 544), [
                [_vModelSelect, _ctx.hotspotId]
              ]),
              _cache[26] || (_cache[26] = _createElementVNode("label", { for: "hotspots" }, "Hotspots", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                id: "hsName",
                name: "name",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.hotspot.name) = $event)),
                type: "text",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.name]
              ]),
              _cache[27] || (_cache[27] = _createElementVNode("label", { for: "hsName" }, "Hotspot name", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _withDirectives(_createElementVNode("input", {
                id: "hsWidth",
                name: "width",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.hotspot.width) = $event)),
                type: "number",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.width]
              ]),
              _cache[28] || (_cache[28] = _createElementVNode("label", { for: "hsWidth" }, "Width", -1))
            ]),
            _createElementVNode("div", _hoisted_21, [
              _withDirectives(_createElementVNode("input", {
                id: "hsHeight",
                name: "heigth",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.hotspot.height) = $event)),
                type: "number",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.height]
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("label", { for: "hsHeight" }, "Height", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _withDirectives(_createElementVNode("select", {
                id: "hsType",
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.hotspot.type) = $event))
              }, _cache[30] || (_cache[30] = [
                _createStaticVNode("<option value=\"phone\">Phone</option><option value=\"image\">Image</option><option value=\"carousel\">Carousel</option><option value=\"audio\">Audio</option><option value=\"browser\">Browser</option><option value=\"component\">Component</option>", 6)
              ]), 512), [
                [_vModelSelect, _ctx.hotspot.type]
              ]),
              _cache[31] || (_cache[31] = _createElementVNode("label", { for: "hsType" }, "Type", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _withDirectives(_createElementVNode("input", {
                id: "hsComp",
                name: "path",
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.hotspot.path) = $event)),
                type: "text",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.path]
              ]),
              _cache[32] || (_cache[32] = _createElementVNode("label", { for: "hsComp" }, "Link", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _withDirectives(_createElementVNode("input", {
                id: "hsYaw",
                name: "yaw",
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.hotspot.yaw) = $event)),
                type: "number",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.yaw]
              ]),
              _cache[33] || (_cache[33] = _createElementVNode("label", { for: "hsYaw" }, "Yaw", -1))
            ]),
            _createElementVNode("div", _hoisted_28, [
              _withDirectives(_createElementVNode("input", {
                id: "hsPitch",
                name: "pitch",
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.hotspot.pitch) = $event)),
                type: "number",
                placeholder: " "
              }, null, 512), [
                [_vModelText, _ctx.hotspot.pitch]
              ]),
              _cache[34] || (_cache[34] = _createElementVNode("label", { for: "hsPitch" }, "Pitch", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("button", {
                class: "editButton",
                type: "button",
                onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.placeHotspot && _ctx.placeHotspot(...args))),
                disabled: (_ctx.hotspotId !== '')
              }, "Place", 8, _hoisted_32),
              _createElementVNode("button", {
                class: "editButton",
                type: "button",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.saveHotspot(_ctx.hotspotId))),
                disabled: (_ctx.hotspot.name === '')
              }, [
                ((_ctx.hotspotId === ''))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_34, "Save"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_35, "Update"))
              ], 8, _hoisted_33),
              _createElementVNode("button", {
                class: "editButton",
                type: "button",
                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.deleteHotspot(_ctx.hotspotId))),
                disabled: (_ctx.hotspotId === '')
              }, "Delete", 8, _hoisted_36)
            ])
          ])
        ])
      ])
    ])
  ]))
}