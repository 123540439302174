// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, collection, getDocs, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator, signInWithEmailAndPassword, signInAnonymously } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    databaseURL: process.env.DATABASE_URL,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const dev = process.env.NODE_ENV === 'development';
console.log("DEV", dev, process.env.HOST_IP);

// init services
const db = getFirestore(app);
if (dev) {
    connectFirestoreEmulator(db, process.env.HOST_IP, 4001);
}

const rtdb = getDatabase(app);
if (dev) {
    connectDatabaseEmulator(rtdb, process.env.HOST_IP, 9001);
}

const storage = getStorage(app);

const auth = getAuth(app);
if (dev) {
    connectAuthEmulator(auth, 'http://' + process.env.HOST_IP + ':9099');
}

const functions = getFunctions(app, 'europe-west1');
if (dev) {
    connectFunctionsEmulator(functions, process.env.HOST_IP, 5001);
}

// init app check for local use
if (dev) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.RECAPTCHA_SITE_KEY),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
    });


export { db, rtdb, auth, functions, httpsCallable, storage, signInWithEmailAndPassword, signInAnonymously }
