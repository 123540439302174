import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "flyout__navi open",
  ref: "navRef"
}
const _hoisted_2 = {
  key: 0,
  class: "navibtn navibtn__fullscreen"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "navibtn navibtn__info" }
const _hoisted_6 = { class: "navibtn navibtn__todoList" }
const _hoisted_7 = { class: "navibtn__calendar" }
const _hoisted_8 = { class: "navibtn__calendar-bottom" }
const _hoisted_9 = ["data-content"]
const _hoisted_10 = { class: "navibtn__inner" }
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (!_ctx.isMobileOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "navibtn__inner",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFullscreen && _ctx.toggleFullscreen(...args)))
          }, [
            (_ctx.fullscreen)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[4] || (_cache[4] = [
                  _createElementVNode("svg", {
                    viewBox: "0 0 32 32",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xml:space": "preserve",
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    "stroke-linejoin": "round",
                    "stroke-miterlimit": "2"
                  }, [
                    _createElementVNode("path", { d: "M7.88 10H5.14a1.5 1.5 0 0 0 0 3H13V5.15a1.5 1.5 0 0 0-3 0V7.9S3.17 1.06 3.15 1.06C1.8-.29-.32 1.8 1.05 3.16l6.83 6.85Zm.1 12H5.24a1.5 1.5 0 0 1 0-3h7.87v7.86a1.5 1.5 0 0 1-3 0v-2.74l-6.85 6.83c-1.36 1.35-3.47-.73-2.1-2.1 0-.02 6.82-6.85 6.82-6.85Zm16.14-12h2.74a1.5 1.5 0 0 1 0 3H19V5.15a1.5 1.5 0 0 1 3 0V7.9s6.83-6.83 6.85-6.83c1.35-1.35 3.47.73 2.1 2.1l-6.83 6.85Zm.11 12h2.74a1.5 1.5 0 0 0 0-3h-7.86v7.86a1.5 1.5 0 0 0 3 0v-2.74l6.84 6.83c1.36 1.35 3.47-.73 2.1-2.1 0-.02-6.82-6.85-6.82-6.85Z" })
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[5] || (_cache[5] = [
                  _createElementVNode("svg", {
                    viewBox: "0 0 31 31",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xml:space": "preserve",
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    "stroke-linejoin": "round",
                    "stroke-miterlimit": "2"
                  }, [
                    _createElementVNode("path", { d: "M25.62 27.5h-2.74a1.5 1.5 0 0 0 0 3h7.86v-7.86a1.5 1.5 0 0 0-3 0v2.74l-7.6-7.6a1.5 1.5 0 0 0-2.14 2.1l7.61 7.61ZM3.25 25.47v-2.74a1.5 1.5 0 0 0-3 0v7.86H8.1a1.5 1.5 0 0 0 0-3H5.37l7.6-7.6a1.5 1.5 0 1 0-2.12-2.13l-7.6 7.61ZM25.64 3.41l-7.2 7.2a1.5 1.5 0 1 0 2.12 2.11l7.2-7.2v2.75a1.5 1.5 0 0 0 3 0V.41H22.9a1.5 1.5 0 0 0 0 3h2.74ZM3.24 5.53l7.2 7.2a1.5 1.5 0 1 0 2.12-2.13l-7.2-7.2H8.1a1.5 1.5 0 0 0 0-3H.24v7.87a1.5 1.5 0 0 0 3 0V5.53Z" })
                  ], -1)
                ])))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "navibtn__inner",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal('Info')))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "material-icons" }, "", -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "navibtn__inner",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal('TodoList')))
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "material-icons" }, "", -1)),
        _createElementVNode("div", _hoisted_7, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "navibtn__calendar-top" }, null, -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.roomId), 1)
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "navibtn__bubble" }, "Du oder jemand aus deinem Team hat das Rätsel gelöst. Wechsel hier zum nächsten Tag.", -1))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playersRef, (player, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["navibtn navibtn__player", {active: index === _ctx.playerId, online: (player.players > 0 && player.id === index)}]),
        key: player.name,
        "data-content": player.players
      }, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("img", {
            src: player.img
          }, null, 8, _hoisted_11)
        ])
      ], 10, _hoisted_9))
    }), 128)),
    _createElementVNode("div", {
      class: "navibtn",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleNav()))
    }, _cache[10] || (_cache[10] = [
      _createElementVNode("div", { class: "navibtn__inner" }, [
        _createElementVNode("span"),
        _createElementVNode("span"),
        _createElementVNode("span")
      ], -1)
    ]))
  ], 512))
}